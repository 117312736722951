import { render, staticRenderFns } from "./cus-upload-video.vue?vue&type=template&id=8c21d778&scoped=true&"
import script from "./cus-upload-video.vue?vue&type=script&lang=js&"
export * from "./cus-upload-video.vue?vue&type=script&lang=js&"
import style0 from "./cus-upload-video.vue?vue&type=style&index=0&id=8c21d778&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c21d778",
  null
  
)

export default component.exports
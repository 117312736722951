<template>
	<el-select class="cus-select" v-model="val" @change="change" :placeholder="placeholder">
		<el-option :label="placeholder" :value="0" v-if="!must">{{placeholder}}</el-option>
		<el-option v-for="item in list" :key="item.id" :label="item.text" :value="item.id">{{item.text}}</el-option>
	</el-select>
</template>
<script>
	export default {
		props: {
			placeholder: {
				type: String,
				default: "请选择"
			},
			value: {
				type: [String, Number],
				default: '',
			},
			list: {
				type: Array,
				default () {
					return []
				}
			},
			must: {
				type: Boolean,
				default: false
			}
		},
		mounted() {
			this.shuval();
		},
		data() {
			return {
				val: '',
			}
		},
		methods: {
			shuval() {
				// console.log(this.value);
				let value = this.value;
				let list = this.list;
				if (this.must && value <= 0 && list.length > 0) {
					value = list[0].id;
				}
				this.val = value;
			},
			change(e) {
				// console.log(e);
				// let value = e;
				if (e == 0 && !this.must) {
					this.val = "";
				}
				this.$emit('change', {
					"value": e
				});
			}
		}
	}
</script>
<style scoped>
	.cus-select {
		border-radius: 4px;
		border: 1px solid #DCDFE6;
		overflow: hidden;
		background-color: #fff;
		position: relative;
	}

	.cus-select /deep/ .el-input__inner {
		border: 0;
		height: 38px;
		line-height: 38px;
	}
</style>

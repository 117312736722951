<template>
	<div class="cus_upvo_box">
		<el-progress v-if="videoFlag" type="circle" :percentage="videoUploadPercent" class="vdo_progress"></el-progress>

		<div v-if="Videosrc != '' && !videoFlag" class="el-video" :style="{'backgroundImage':`url(${get_imgsrc})`}">
			<div class="img_play">
				<i class="el-icon-caret-right"></i>
			</div>
			<div class="vdo_play" @click="vdo_play"></div>
			<div class="img_del">
				<i class="el-icon-close" @click="beforeRemove"></i>
			</div>
		</div>
		<div v-if="Videosrc == '' && !videoFlag">
			<el-upload class="avatar-uploader el-upload--text"
				:action="websiteUrl + '/adminshop/uploads/uploadvideo.html'" :show-file-list="false"
				:on-success="handleVideoSuccess" :on-progress="uploadVideoProcess">
				<div class="el-upicon">
					<i class="el-icon-plus avatar-uploader-icon"></i>
				</div>
			</el-upload>

		</div>
		<el-dialog :modal-append-to-body="false" :visible="imgVisible" @close="closeDialog" width="30%">
			<video :src="get_vosrc" controls autoplay class="video" width="100%"></video>

		</el-dialog>

	</div>
</template>

<script>
	import helper from "../../common/helper";

	export default {
		props: {
			imgname: {
				type: String,
				default: "",
			},
			src: {
				type: String,
				default: "",
			},
			vosrc: {
				type: String,
				default: "",
			},
			accept: {
				type: String,
				default: "",
			}
		},

		mounted() {
			this.get_img();
		},
		data() {
			return {
				//上传身份证正面相关参数
				limitCount: 1,
				// 是否显示预览图片
				imgVisible: false,
				websiteUrl: helper.websiteUrl,

				videoFlag: false,
				videoUploadPercent: "",
				Videosrc: "",
				imgsrc: "",
			};
		},
		computed: {
			get_imgsrc() {
				let img_src = this.imgsrc;
				if (!(img_src.indexOf("http://") === 0 || img_src.indexOf("https://") === 0)) {
					img_src = this.websiteUrl + img_src;
				}
				return img_src;
			},
			get_vosrc() {
				let vosrc = this.Videosrc;
				if (!(vosrc.indexOf("http://") === 0 || vosrc.indexOf("https://") === 0)) {
					vosrc = this.websiteUrl + vosrc;
				}
				return vosrc;
			}
		},
		methods: {
			get_img() {
				let img_src = this.src;
				let vosrc = this.vosrc;
				if (img_src != "" && vosrc != "") {
					if (!(img_src.indexOf("http://") === 0 || img_src.indexOf("https://") === 0)) {
						img_src = this.websiteUrl + img_src;
					}
					if (!(vosrc.indexOf("http://") === 0 || vosrc.indexOf("https://") === 0)) {
						vosrc = this.websiteUrl + vosrc;
					}
					this.Videosrc = vosrc;
					this.imgsrc = img_src;
				}
			},
			uploadVideoProcess(event, file) {
				console.log(event);
				this.videoFlag = true;
				this.videoUploadPercent = parseInt(file.percentage);
			},
			handleVideoSuccess(response) {
				// console.log(response);
				this.videoFlag = false;
				this.videoUploadPercent = 0;
				if (response.status == 1) {
					var video_upsrc = response.video_upsrc
					var img_upsrc = response.img_upsrc;
					this.Videosrc = video_upsrc;
					this.imgsrc = img_upsrc;
					this.chageimg(video_upsrc, img_upsrc);
				} else {
					this.$message.error(response.msg);
				}
			},
			beforeRemove() {
				this.$confirm(`确定移除这个视频码？`).then(() => {
					this.$message.success("已移除");
					this.Videosrc = "";
					this.imgsrc = "";
					this.chageimg();
				}).catch(() => {});
			},
			vdo_play() {
				this.imgVisible = true;
			},
			closeDialog() {
				this.imgVisible = false;
			},
			chageimg(src = "", img_src = "") {
				this.$emit("change", {
					value: src,
					img_src: img_src
				});
			},
		},
	};
</script>

<style scoped>
	.cus_upimg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.el-upicon {
		text-align: center;
		background-color: #fbfdff;
		border: 1px dashed #c0ccda;
		border-radius: 6px;
		box-sizing: border-box;
		width: 148px;
		height: 148px;
		line-height: 146px;
		vertical-align: top;
	}

	.el-video {
		width: 148px;
		height: 148px;
		border-radius: 6px;
		background-color: #fbfdff;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		cursor: pointer;
		position: relative;
	}

	.vdo_play {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.img_del {
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background-color: red;
		text-align: center;
		line-height: 25px;
		position: absolute;
		right: -5px;
		top: -5px;
		cursor: pointer;

	}

	.img_del i {
		font-size: 15px;
		color: #fff;
	}

	.el-upicon i {
		font-size: 28px;
		color: #8c939d;
	}

	.img_play {
		position: absolute;
		width: 35px;
		height: 35px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 3px solid #fff;
		border-radius: 50%;
	}

	.img_play i {
		font-size: 30px;
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.vdo_progress {
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}

	.vdo_progress /deep/ .el-progress-circle {
		width: 148px !important;
		height: 148px !important;
	}
</style>
